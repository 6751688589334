<template>
  <div>
    <MallheaderVue
      :customstyle="{ background: '#FFFFFF' }"
      :titlename="'申请商家'"
    ></MallheaderVue>
    <div class="bgcontent">
      <img
        class="waiticon"
        :src="require('@/assets/images/mall/shop/wait.png')"
      />
      <div class="waittxt">待审核！请等待...</div>
      <div class="btn">返回首页</div>
    </div>
  </div>
</template>

<script>
import MallheaderVue from "../../components/mallheader.vue";
export default {
  components: {
    MallheaderVue,
  },
};
</script>

<style lang="scss" scoped>
.bgcontent {
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .waiticon {
    margin-top: 208px;
    width: 320px;
    height: 320px;
  }
  .waittxt {
    color: rgba(105, 98, 95, 1);
    font-size: 28px;
    font-weight: 700;
    font-family: "PingFang SC";
    text-align: center;
  }
  .btn {
    margin-top: 64px;
    width: 524px;
    height: 72px;
    border-radius: 74px;
    opacity: 1;
    background: rgba(255, 114, 32, 1);
    color: rgba(238, 238, 238, 1);
    font-size: 28px;
    font-weight: 700;
    font-family: "PingFang SC";
    text-align: center;
    line-height: 72px;
  }
}
</style>